






















































































































































import {Vue, Component, Prop} from 'vue-property-decorator';
import {IEmployment} from "@/dto/payroll/IEmployment";
import {BalanceDetailsFilterForPeriod, VacationBalanceDetailsDTO} from "@/dto/payroll/VacationDTO";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import VacationService from "@/services/payroll/VacationService";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import {WorkspaceType} from "@/dto/auth/Workspace";
import EmploymentService from "@/services/EmploymentService";
import { EmploymentFilter } from "@/dto/payroll/Filters";
import { debounce } from "typescript-debounce-decorator";
import { CounterpartyType } from "@/constants/CounterpartyType";
import { EmploymentStatus } from "@/components/payroll/business/employments/contractors/Contractor";
import SelectOption from "@/components/common/SelectOption";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import moment from "moment";

@Component({
	computed: {
		WorkspaceType() {
			return WorkspaceType
		}
	},
	components: {PortalRadio, PortalInput, PortalDate, PaginationComponent},
})
export default class VacationBalanceDetails extends Vue {

	@Prop()
	employment!: IEmployment

	private balanceDetails: Array<VacationBalanceDetailsDTO> = [];
	private balanceDetailsFilter = new BalanceDetailsFilterForPeriod();
	private totalBalanceDays: number | null = null;
	private totalBalanceHours: number | null = null;
	private employmentsTotalPages = 0;
	private balanceDetailsTotalPages = 0;
	private searchMode = true;
	private selectedEmployee: IEmployment | null = null;
	private employmentsFilter = new EmploymentFilter(
		{
			types: ["STAFF", "FOREIGN"],
			contractorType: CounterpartyType.PERSON,
			status: [EmploymentStatus.ACTIVE, EmploymentStatus.NEW, EmploymentStatus.PENDING_FOR_DOCUMENTS]
		}
	)
	private employments: Array<IEmployment> = [];
	private timeFormat = "hh:mm A";
	private countFilters = 0;

	async mounted(): Promise<void> {
		await this.loadEmployments();
		if (this.employments.length === 1) {
			await this.goToPersonVacationDetails(this.employments[0]);
		}
	}

	@debounce(500)
	search() {
		this.loadEmployments();
	}

	private async loadEmployments() {
		try {
			Application.startLoading();
			if (this.$wss.getCurrent.type === WorkspaceType.PERSON) {
				this.employmentsFilter.detailsId = this.id;
			} else {
				this.employmentsFilter.employerId = this.id;
			}
			const res = await EmploymentService.getPageByFilter<IEmployment>(this.employmentsFilter);
			this.employments = res.data.data;
			this.employmentsTotalPages = res.data.countOfPages;
		} catch (err) {
			processError(err, this);
		} finally {
			Application.stopLoading();
		}
	}

	private loadBalanceDetails() {
		Application.startLoading();
		return VacationService.getBalanceDetails(this.balanceDetailsFilter).then(
			res => {
				this.balanceDetails = res.data.data;
				this.balanceDetailsTotalPages = res.data.countOfPages;
				Application.stopLoading();
			},
			err => processError(err, this)
		)
	}

	private loadBalanceDetailsForPeriod() {
		Application.startLoading();
		VacationService.getBalanceDetailsForPeriod(this.balanceDetailsFilter)
			.then(
				res => {
					this.balanceDetails = res.data.data;
					this.balanceDetailsTotalPages = res.data.totalPages;
					this.totalBalanceDays = res.data.balance;
					this.totalBalanceHours = res.data.balanceForPeriod;
					Application.stopLoading();
				},
				err => processError(err, this)
			)
	}

	private loadAvailableBalance() {
		Application.startLoading();
		return VacationService.getVacationBalance(this.balanceDetailsFilter.employmentId!).then(
			res => {
				this.totalBalanceDays = parseFloat(res.data.totalBalanceDays);
				this.totalBalanceHours = parseFloat(res.data.totalBalanceHours);
				Application.stopLoading();
			},
			err => processError(err, this)
		)
	}

	private changeEmploymentsPage(page: number) {
		this.employmentsFilter.pageNumber = page - 1;
		this.loadEmployments();
	}

	private changeBalanceDetailsPage(page: number) {
		this.balanceDetailsFilter.page = page - 1;
		if (this.balanceDetailsFilter.startDate || this.balanceDetailsFilter.endDate) {
			this.loadBalanceDetailsForPeriod();
		} else {
			this.loadBalanceDetails();
		}
	}

	private async goToPersonVacationDetails(employee: IEmployment) {
		this.selectedEmployee = employee;
		this.balanceDetailsFilter.employmentId = this.selectedEmployee.id;
		this.searchMode = false;
		await this.loadBalanceDetails();
		await this.loadAvailableBalance();
	}

	private async resetFilters() {
		this.balanceDetailsFilter.page = 0;
		this.balanceDetailsFilter.startDate = null;
		this.balanceDetailsFilter.endDate = null;
		this.countFilters = 0;
		await this.loadBalanceDetails();
		await this.loadAvailableBalance();
	}

	private confirmFilters() {
		this.balanceDetailsFilter.page = 0;
		this.countFilters = 0;
		if (this.balanceDetailsFilter.startDate) {
			this.countFilters += 1;
		}
		if (this.balanceDetailsFilter.endDate) {
			this.countFilters += 1;
		}
		this.loadBalanceDetailsForPeriod();
	}

	private getPrettyTime(bd: VacationBalanceDetailsDTO) {
		return `${moment(bd.createDate).format(this.timeFormat)}`;
	}

	private getPrettyDate(bd: VacationBalanceDetailsDTO) {
		return ` • ${moment(bd.createDate).format('D MMM YYYY')}`;
	}

	get prettyTotalBalance(): string {
		return `Balance: ${this.totalBalanceDays?.toFixed(2)} days (${this.totalBalanceHours?.toFixed(0)} hours)`;
	}

	get id(): number {
		return this.$wss.getCurrent.id;
	}

	get timeFormats(): SelectOption[] {
		return [
			SelectOption.builder().title("12 hours").value("hh:mm A").build(),
			SelectOption.builder().title("24 hours").value("HH:mm").build()
		]
	}

}
